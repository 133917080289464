import { useState, useEffect } from 'react'

import { getParameterByName, getCookie } from 'src/shared/helpers'

type UtmLinkProps = {
  link: string;
  campanha?: string;
}

const UtmLink = ({ link, campanha }: UtmLinkProps) => {
  const [ gclid, setGclid ] = useState<string | string[] | null | undefined>(null)
  const [ fbclid, setFbclid ] = useState<string | string[] | null | undefined>(null)
  const [ gaid, setGaid ] = useState<string>()
  const [ utm_source, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utm_medium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utm_campaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utm_content, setUtmContent ] = useState<string | string[] | null | undefined>(null)
  const [ utm_term, setUtmTerm ] = useState<string | string[] | null | undefined>(null)

  useEffect(() => {
    setGclid(getParameterByName('gclid', ''))
    setFbclid(getParameterByName('fbclid', ''))
    setGaid(getCookie('_ga'))
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
    setUtmTerm(getParameterByName('utm_term', ''))
  }, [])

  const generateSimulatorLink = (link: string) => {
    const hasQueryParam = link.includes('?')

    const parametersGclid = gclid ? `&gclid=${gclid}` : ''
    const parametersFbclid = fbclid ? `&fbclid=${fbclid}` : ''
    const parametersGaid = gaid ? `&gaid=${gaid}` : ''
    const parametersUtmSource = utm_source ? `&utm_source=${utm_source}` : ''
    const parametersUtmMedium = utm_medium ? `&utm_medium=${utm_medium}` : ''
    const parametersUtmCampaign = utm_campaign ? `&utm_campaign=${utm_campaign}` : ''
    const parametersUtmContent = utm_content ? `&utm_content=${utm_content}` : ''
    const parametersUtmTerm = utm_term ? `&utm_term=${utm_term}` : ''
    const isCampanha = campanha !== '' && campanha !== undefined ? `campanha=${campanha}` : ''
    const utmLinkWithOutQueryParam = `${link}${isCampanha}${parametersGclid}${parametersGaid}${parametersFbclid}${parametersUtmSource}${parametersUtmMedium}${parametersUtmCampaign}${parametersUtmContent}${parametersUtmTerm}`
    const utmLink = `${link}?${isCampanha}${parametersGclid}${parametersGaid}${parametersFbclid}${parametersUtmSource}${parametersUtmMedium}${parametersUtmCampaign}${parametersUtmContent}${parametersUtmTerm}`
    return hasQueryParam ? utmLinkWithOutQueryParam : utmLink
  }

  return generateSimulatorLink(link)
}

export default UtmLink
