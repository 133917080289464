import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import QuaisAsVantagensJSON from '../../assets/data/quais-as-vantagens.json'
import { Section, IconContainer } from './style'

type SimplifiqueAAberturaProps = {
  setIsOpen: (isOpen: boolean) => void;
  setDataLayer: (state: IDataLayerParams) => void;
};
interface IIndicateJson {
  icon: string;
  title: string;
  description: string;
}

const QuaisAsVantagens = ({ setIsOpen, setDataLayer }: SimplifiqueAAberturaProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_02',
    section_name: 'Quais as vantagens do sistema de Gestão de Cobranças?',
    element_action: 'click button',
    element_name: 'Baixar EBook',
  }

  return (
    <Section className='py-5 bg-blue--light'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-10 col-xl-8 mx-auto'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-center fw-600 font-sora text-grayscale--500 mb-3'>Quais as vantagens do sistema de Gestão de Cobranças?
            </h2>
            <p className='fs-14 lh-16 fs-md-16 lh-md-19 fs-xl-18 lh-xl-22 text-center text-grayscale--500 mb-4'>
              Utilizando o sistema de cobranças do Inter Empresas, é possível garantir vários benefícios para o seu negócio. Confira alguns:
            </p>
          </div>

          <div className='row d-flex justify-content-md-center'>
            {QuaisAsVantagensJSON.map((data: IIndicateJson) => (
              <div
                key={data.icon}
                className='d-flex col-12 col-md-9 col-lg-6 mb-4 align-items-center'
              >
                <div className='col-12 bg-white rounded-3 p-3 h-100'>
                  <div className='d-flex align-items-center mb-3 '>
                    <IconContainer className='d-flex justify-content-center align-items-center bg-green--extralight'>
                      <OrangeIcon size='MD' color='#FFFFFF' icon={data.icon} />
                    </IconContainer>
                    <div>
                      <h3 className='fs-16 lh-19 fw-700 text-grayscale--400 mb-0 ml-3'>{data.title}</h3>
                    </div>
                  </div>
                  <p className='fs-14 lh-16 fs-md-16 lh-md-19 text-grayscale--400 mb-0'>{data.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className='col-12 text-center'>
            <button
              className='btn btn-orange--extra btn--lg text-none fs-14 fw-700 rounded-3 mt-4'
              onClick={() => {
                setIsOpen(true)
                sendDatalayerEvent(dataLayer)
                setDataLayer(dataLayer)
              }}
              type='button'
            >
              Baixar EBook
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default QuaisAsVantagens
