import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const PrincipaisPossibilidadesSection = styled.section`

  .text-div {
    @media ${device.desktopLG} {
      flex-grow: 1;
    }
  }
`
