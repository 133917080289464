import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'
import useUtms from 'src/hooks/useUtms'

import BaixarEbookForm from './components/BaixarEbookForm/_index'

import pageContext from './pageContext.json'

import { GestaoDeCobrancas } from './sections/gestao-de-cobrancas/_index'
import QuaisAsVantagens from './sections/quais-as-vantagens/_index'
import PrincipaisPossibilidades from './sections/principais-possibilidades/_index'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const ComoAbrirEmpresa = () => {
  const domReady = useDomReady()
  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: 'Gestao de Cobrancas' })

  const [ isOpen, setIsOpen ] = useState(false)
  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Gestão de Cobranças Inter Empresas: seu sistema anti-inadimplência!',
    element_action: 'click button',
    element_name: 'Baixar agora',
  })

  const baixeOLivroModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <BaixarEbookForm
        closeModal={() => setIsOpen(false)}
        dataLayer={dataLayer}
        cpfStatus='modal'
        htmlForSim='modal-sim'
        htmlForNao='modal-nao'
      />
    </Modal>
  )

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }

  return (
    <Layout handleHeaderOpenAccount={() => openAccountPJFormModal()} pageContext={pageContext}>
      {baixeOLivroModal}
      <GestaoDeCobrancas setIsOpen={setIsOpen} setDataLayer={setDataLayer} />
      <QuaisAsVantagens setIsOpen={setIsOpen} setDataLayer={setDataLayer} />
      <PrincipaisPossibilidades />
    </Layout>
  )
}

export default ComoAbrirEmpresa
