import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

type BreakpointsType = {
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
};

interface IImgWebpProps {
  breakPointsSize: BreakpointsType;
}

export const ImgWebp = styled.img<IImgWebpProps>`
  width: ${(props: IImgWebpProps) => props.breakPointsSize.sm};

  @media ${device.tablet} {
    width: ${(props: IImgWebpProps) => props.breakPointsSize.md};
  }

  @media ${device.desktopLG} {
    width: ${(props: IImgWebpProps) => props.breakPointsSize.lg};
  }

  @media ${device.desktopXL} {
    width: ${(props: IImgWebpProps) => props.breakPointsSize.xl};
  }

  @media ${device.desktopXXL} {
    width: ${(props: IImgWebpProps) => props.breakPointsSize.xxl};
  }
`
