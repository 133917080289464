import React, { MouseEvent } from 'react'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import errorImg from '../../assets/images/error.png'

import { FormError, CloseButton } from '../style'

type OpenAccountFormProps = {
  handleReturn: (e: MouseEvent<HTMLButtonElement>) => void;
};

const Error = ({
  handleReturn,
}: OpenAccountFormProps) => {
  return (
    <FormError
      className='d-flex position-relative align-items-center justify-content-center py-3 px-3'
    >
      <CloseButton className='border-0 bg-transparent pt-4' onClick={handleReturn}>
        <Close width='20' height='20' color='orange--extra' />
      </CloseButton>
      <div className='col-12 text-center mx-auto'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 my-3'>
            <img src={errorImg} alt='Imagem de erro' />
          </div>
          <div className='col-12 col-md-10 col-lg-12 my-2 my-md-0'>
            <p className='fs-18 lh-20 fs-md-24 lh-md-30 text-center text-grayscale--500 fw-600 mb-2'>
              Ocorreu um erro
            </p>
            <p className='fs-14 lh-16 fs-md-18 lh-md-22 text-grayscale--500 mb-3 mb-lg-4'>
              Não foi possível efetuar o cadastro.
              Tente novamente mais tarde.
            </p>
            <div className='back'>
              <button
                className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-grayscale--100 text-none mw-100'
                onClick={handleReturn}
              >
                Entendi
              </button>
            </div>
          </div>
        </div>
      </div>
    </FormError>
  )
}

export default Error
