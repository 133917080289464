import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import Detail from '../../assets/images/section-detail.png'
import BgMd from '../../assets/images/bg-hero-md.png'
import BgLg from '../../assets/images/bg-hero-lg.png'
import BgXl from '../../assets/images/bg-hero-xl.png'

export const Section = styled.section`
  @media ${device.tablet} {
    background-image: url(${BgMd});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;

    .image {
      margin: 0 auto;
      display: flex;

      @media ${device.tablet} {
        margin: 0;
      }
    }

    .form-hero {
      a {
        color: ${white};
      }
    }
}

  @media ${device.desktopLG} {
    background-image: url(${BgLg});
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    background-image: url(${BgXl});
    background-position: 108% 80%;
    padding-top: 96px;
    padding-bottom: 96px;
  }

  @media ${device.desktopXXXL} {
    background-size: 1000px;
    background-image: url(${BgXl});
    background-position: 100% 80%;
    background-repeat: no-repeat;
  }
`

export const FormMobile = styled.div`
  background-color: ${orange.extra};
  background-image: url(${Detail});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;

  a {
    color: ${white};
  }
`
