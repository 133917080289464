import styled from 'styled-components'

export const Section = styled.section`
  background-color: #DDF0EF;
`

export const IconContainer = styled.div`
  background-color: #409B97;
  width: 46px;
  height: 46px;
  border-radius: 50%;
`
