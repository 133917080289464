import React from 'react'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { FormMobile, Section } from './style'

import ImageWebp from 'src/components/ImageWebp'
import BaixarEbookForm from '../../components/BaixarEbookForm/_index'

export const GestaoDeCobrancas = () => {
  const width = useWidth()

  const arrayNumbers: number[] = [
    309, 278, 396, 450,
  ]

  const arrayHeight: number[] = [
    229, 205, 293, 333,
  ]

  return (
    <>
      <Section className='bg-white py-3'>
        <div className='container'>
          <div className='row align-items-md-center'>
            <div className='col-12 col-md-6 col-lg-5'>
              <div className='d-flex justify-content-center justify-content-md-start mt-md-5 mb-md-5'>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/gestao-cobranca-hero/image.webp'
                  altDescription='Tela de acesso ao sistema de cobranças no Internet Banking Inter Empresas'
                  arrayNumbers={arrayNumbers}
                  arrayNumbersHeight={arrayHeight}
                  className='image'
                />
              </div>
              <h1 className='font-sora fs-20 fs-md-24 lh-25 fs-lg-32 lh-md-30 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 mb-3 text-grayscale--500'>
                <span className='text-orange--extra'><span className='d-xl-block'>Gestão de Cobranças</span> Inter Empresas: </span>
                <span className='d-block text-green--500 fw-400'>seu sistema <span className='d-block'>anti-inadimplência!</span></span>
              </h1>
              <p className='fs-12 lh-15 fs-md-14 fs-xl-18 lh-md-17 lh-xl-22 text-grayscale--500 mb-3 pb-2'>
                <span className='d-xl-block'>Devido a suas diversas funcionalidades,</span> <span className='d-xl-block'>esse sistema é capaz de automatizar</span>  <span className='d-xl-block'>processos,contribuindo na redução da taxa de</span> <span className='d-xl-block'>inadimplência e garantindo mais economia</span> para o seu negócio.
              </p>
            </div>
            {width >= WIDTH_MD &&
              <div className='col-md-5 col-lg-6 col-xl-5 offset-md-1 offset-lg-1 offset-xl-2 form-hero'>
                <BaixarEbookForm
                  cpfStatus='hero'
                  htmlForSim='hero-sim'
                  htmlForNao='hero-nao'
                  formHero='hero'
                />
              </div>
            }
          </div>
        </div>
      </Section>
      {width < WIDTH_MD &&
        <FormMobile className='py-2'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <BaixarEbookForm
                  cpfStatus='hero'
                  htmlForSim='hero-sim'
                  htmlForNao='hero-nao'
                  formHero='hero'
                />
              </div>
            </div>
          </div>
        </FormMobile>
      }
    </>
  )
}
