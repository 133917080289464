import React, { useState, MouseEvent, ChangeEvent, useEffect } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'

import ErrorImg from './assets/images/error.png'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import Success from './assets/images/success.png'

import useDomReady from 'src/hooks/window/useDomReady'
import { Modal } from 'src/components/Modal'

import Error from './status/error/_index'
import Sent from './status/sent/_index'

import { Container, CloseButton, Checkbox } from './style'
import { sendCDPFormData, getParameterByName } from 'src/shared/helpers'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

type BaixarEbookFormProps = {
  closeModal?: () => void;
  dataLayer?: IDataLayerParams;
  formHero?: string;
  cpfStatus: string;
  htmlForSim: string;
  htmlForNao: string;
}

interface IFormValues {
  nome: string;
  email: string;
  celular: string;
  CpfCnpj: string;
  cliente?: string;
  range: string;
}

function BaixarEbookForm ({ closeModal, dataLayer, formHero, cpfStatus, htmlForSim, htmlForNao }: BaixarEbookFormProps) {
  const domReady = useDomReady()
  const { register, errors, handleSubmit, setValue }: Pick<ReturnType<typeof useForm>, 'register' | 'errors' | 'handleSubmit' | 'setValue'> = useForm()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ isClient, setIsClient ] = useState(true)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ isOpenSent, setIsOpenSent ] = useState(false)
  const [ isOpenError, setIsOpenError ] = useState(false)

  useEffect(() => {
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    const isClientFormatted = isClient ? 'Sim' : 'Nao'

    setLoading(true)

    const formData = [ {
      campanha: 'Gestao de Cobrancas',
      nome: data.nome,
      CpfCnpj: data.CpfCnpj.replace(/\D/g, ''),
      email: data.email,
      conteudo01: isClientFormatted,
      conteudo02: utmCampaign?.toString(),
      conteudo03: utmMedium?.toString(),
      aceite: accept,
    } ]

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setIsOpenSent(true)
      setSent(true)
      sendCDPFormData({ formName: 'Gestao de Cobrancas', cpf: data.CpfCnpj.replace(/\D/g, ''), email: data.email })
      sendDatalayerEvent({
        section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0 ',
        section_name: dataLayer ? dataLayer.section_name : '',
        element_search: `Possui CNPJ ativo? - ${isClientFormatted}`,
        element_action: 'submit',
        element_name: 'Enviar',
        element_previous: dataLayer ? `${dataLayer.element_name}, ${dataLayer.section}` : '',
        step: 'success',
      })
    } catch (e) {
      setError(true)
      setLoading(false)
      setIsOpenError(true)
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
    setIsOpenSent(false)
    setIsOpenError(false)
  }

  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-3' onClick={closeModal}>
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  const sentModal = domReady && (
    <Modal isModalOpen={isOpenSent} setIsModalOpen={setIsOpenSent} locationToRender={document.body}>
      <Sent handleReturn={handleReturn} />
    </Modal>
  )

  const errorModal = domReady && (
    <Modal isModalOpen={isOpenError} setIsModalOpen={setIsOpenError} locationToRender={document.body}>
      <Error handleReturn={handleReturn} />
    </Modal>
  )

  return (
    <>
      {formHero && sentModal}
      {formHero && errorModal}
      { !formHero && error && (
        <Container isModal={closeModal} className='d-flex align-items-center error'>
          {closeButton}
          <div className='col-12 text-center'>
            <img src={ErrorImg} alt='Imagem de erro' />
            <h3 className='fs-32 lh-24 mt-4 mb-3'>Ocorreu um erro</h3>
            <p className='text-grayscale--400 fs-16 lh-22 pt-3 mb-4'>
              <span className='d-block'>Não foi possível efetuar o cadastro.</span> Tente novamente mais tarde.
            </p>
            <button
              onClick={closeModal}
              title='Clique aqui para voltar'
              className='btn fs-14 btn text-white btn--lg btn-orange--extra text-none rounded-3 fw-700'
            >
              Entendi
            </button>
          </div>
        </Container>
      )}

      {!formHero && sent && (
        <Container isModal={closeModal} className='d-flex align-items-center sent'>
          {closeButton}
          <div className='col-12 text-center px-0'>
            <div className='col-12 mx-auto text-center mb-4'>
              <img src={Success} alt='Sucesso' className='sucesso' />
            </div>
            <p className='fs-24 lh-30 text-grayscale--500 fw-600 font-sora'>
              Parabéns, seu cadastro foi realizado com sucesso!
            </p>
            <a
              href='https://marketing.bancointer.com.br/empresas/documentos/eBook-gestao-de-cobrancas.pdf'
              target='blank'
              className='btn fs-14 btn text-white btn--lg btn-orange--extra text-none rounded-3 fw-700'
            >
              Fazer download
            </a>
            <p className='fs-14 lh-16 fs-md-16 lh-md-19 text-grayscale--400 text-center mt-4'>
              Mas caso não possa realizar o download agora, fique tranquilo! Em pouco tempo você receberá o eBook na sua caixa de e-mail.
            </p>
          </div>
        </Container>
      )}

      <Container
        isModal={closeModal}
        className={`${(!formHero && error) || (!formHero && sent) ? 'd-none' : 'd-flex'} ${formHero && 'hero'}  align-items-md-center align-items-lg-start`}
      >
        {closeButton}
        <form name='open_account' onSubmit={handleSubmit(sendForm)}>
          <div className='form--default'>
            <div className='row'>
              <div className='col-12 pt-3 pb-4 mb-2'>
                <h3 className='fs-24 lh-30 text-center text-grayscale--500 mb-3'>
                  Baixe seu eBook gratuitamente
                </h3>
              </div>

              <div className={`col-12 pb-3 d-flex flex-column ${errors.nome && 'error'}`}>
                <label htmlFor='nome' className='fs-14 lh-17 text-grayscale--400'>Nome</label>
                <input
                  ref={register({
                    required: 'Nome obrigatório',
                  })}
                  name='nome'
                  id='nome'
                  type='text'
                  maxLength={100}
                  className={`${errors.nome ? 'error' : ''}`}
                  placeholder={formHero && errors.nome ? errors.nome.message : 'Digite seu nome'}
                />
                {!formHero && errors.nome && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.nome.message}</p>}
              </div>

              <div className={`col-12 pb-3 d-flex flex-column ${errors.email && 'error'}`}>
                <label htmlFor='email' className='fs-14 lh-17 text-grayscale--400'>E-mail</label>
                <input
                  ref={register({
                    required: 'E-mail obrigatório',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Insira um e-mail válido',
                    },
                  })}
                  name='email'
                  id='email'
                  type='text'
                  placeholder={formHero && errors.email ? errors.email.message : 'Digite o seu e-mail'}
                />
                {!formHero && errors.email && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.email.message}</p>}
              </div>

              <div className={`col-12 pb-3 d-flex flex-column ${errors.CpfCnpj && 'error'}`}>
                <label htmlFor='CpfCnpj' className='fs-14 lh-17 text-grayscale--400'>CPF</label>
                <input
                  ref={register({
                    required: 'CPF obrigatório',
                    validate: {
                      isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                    },
                  })}
                  name='CpfCnpj'
                  id='CpfCnpj'
                  type='tel'
                  className={`${errors.CpfCnpj ? 'error' : ''}`}
                  placeholder={formHero && errors.CpfCnpj ? errors.CpfCnpj.message : 'Digite o seu CPF'}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('CpfCnpj', Masks.MaskCPF(event.currentTarget.value))}
                />
                {!formHero && errors.CpfCnpj && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.CpfCnpj.message}</p>}
              </div>

              <div
                className={`col-12 d-flex flex-column flex-md-row justify-content-md-between align-items-md-center ${errors.cliente && 'error'}`}
              >
                <label htmlFor='estado' className='fs-14 lh-17 text-grayscale--500 mb-md-0'>Você possui um CNPJ ativo?</label>
                <div className='d-flex justify-content-start pt-1 pt-md-0'>
                  <label className='radio-contato' htmlFor={htmlForSim}>
                    <input
                      ref={register({
                        required: 'Informação obrigatória',
                      })}
                      type='radio'
                      id={htmlForSim}
                      name={cpfStatus}
                      value='sim'
                      onClick={() => setIsClient(true)}
                    />
                    <span className='radio-check' />
                    Sim
                  </label>
                  <label className='radio-contato' htmlFor={htmlForNao}>
                    <input
                      ref={register({
                        required: 'Informação obrigatória',
                      })}
                      type='radio'
                      id={htmlForNao}
                      name={cpfStatus}
                      value='nao'
                      onClick={() => setIsClient(false)}
                    />
                    <span className='radio-check' />
                    Não
                  </label>
                </div>
                {errors.cliente && <p className='fs-12 mt-1 mb-0 pt-1 text-red--base text-right'>{errors.cliente.message}</p>}
              </div>

              <div className='col-12 d-flex flex-column mt-4'>
                <Checkbox onClick={() => setAccept(!accept)}>
                  <input
                    type='checkbox'
                    className='form-input-check'
                    checked={accept}
                  />
                  <label className='form-label-check fs-12 lh-15 pt-lg-1 text-grayscale--400'>
                    Autorizo o Inter a tratar meus dados pessoais para envio de comunicações sobre seus produtos e serviços e também estou de acordo com a{' '}
                    <a
                      className='fw-700 text-orange--extra'
                      title='Acessar Política de Privacidade do Inter'
                      href='https://inter.co/politica-de-privacidade/privacidade/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Política de Privacidade
                    </a>.
                  </label>
                </Checkbox>
              </div>

              <div className='col-12 text-center mb-4 mb-md-0'>
                <button
                  type='submit' title='Enviar contato' disabled={!accept || loading}
                  className={`btn ${formHero ? 'btn-white text-grayscale--500' : 'btn-orange--extra text-white'} fs-14 fw-600 rounded-2 mx-auto text-none mb-4 mb-xl-0`}
                >
                  {loading ? 'Enviando...' : 'Enviar'}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  )
}

export default BaixarEbookForm
